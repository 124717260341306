import React, { Fragment, useState } from "react";

import moment from "moment";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { FaCalendarAlt } from "react-icons/fa";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import { url } from "../../../services/api_function";
import { useSelector } from "react-redux";

const AdminChnage = () => {
  const token = useSelector((state) => state.auth.auth.token);
  const [userBlock, setuserBlock] = useState("");
  const [userUnblock, setuserUnblock] = useState("");

  const handleBlock = () => {
    if (userBlock.trim() !== "") {
      axios
        .post(
          url + "/withdraw-block",
          {
            user: userBlock,
            status: "1",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response.status == 200) {
            NotificationManager.success(response.data.message);
            setuserBlock("");
          } else {
            NotificationManager.error(response.data.message);
          }
        })
        .catch((error) => {
          NotificationManager.error(
            error.response?.data?.message || "An error occurred"
          );
        });
    }
  };

  const handleUnBlock = () => {
    if (userUnblock.trim() !== "") {
      axios
        .post(
          url + "/withdraw-block",
          {
            user: userUnblock,
            status: "0",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          if (response.status == 200) {
            NotificationManager.success(response.data.message);
            setuserBlock("");
          } else {
            NotificationManager.error(response.data.message);
          }
        })
        .catch((error) => {
          NotificationManager.error(
            error.response?.data?.message || "An error occurred"
          );
        });
    }
  };
  return (
    <Fragment>
      <div className="row">
        <div className="col-xl-6 col-lg-6">
          <div className="card">
            <div className="card-header">
              <h4 className="center" style={{ margin: "auto" }}>
                Withdraw Block
              </h4>
            </div>
            <div className="card-body ">
              <div className="basic-form">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="form-group mb-3">
                    <input
                      type="text"
                      className="form-control input-default"
                      placeholder="User"
                      value={userBlock}
                      onChange={(e) => setuserBlock(e.target.value)}
                    />
                  </div>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleBlock}
                  >
                    Block
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6 col-lg-6">
          <div className="card">
            <div className="card-header">
              <h4 className="center" style={{ margin: "auto" }}>
                Withdraw Block
              </h4>
            </div>
            <div className="card-body ">
              <div className="basic-form">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="form-group mb-3">
                    <input
                      type="text"
                      className="form-control input-default"
                      placeholder="User"
                      value={userUnblock}
                      onChange={(e) => setuserUnblock(e.target.value)}
                    />
                  </div>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={handleUnBlock}
                  >
                    Unblock
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AdminChnage;
