import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import Web3 from "web3";
//import { Autoplay } from "swiper";
import { dashboardData } from "../../../../services/api_function";
import "swiper/css";

import TotalBalanceArea from "./TotalBalanceArea";
import ProfitLossArea from "./ProfitLossArea";
import TotaldipositChart from "./TotaldipositChart";
import { token_abi, token_address } from "../../../config/config";

const BalanceCardSlider = () => {
  const [data, setData] = useState(null);
  const [pool, setPool] = useState(0);
  const navigate = useNavigate();

  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dashboardData(token);
        setData(response);
        if (response.status == 404) {
          navigate("/login");
          localStorage.removeItem("userDetails");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    return () => {};
  }, [navigate]);
  if (!data) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-2">
          <div className="card card-wiget">
            <div className="card-body">
              <div className="card-wiget-info">
                <h4 className="count-num">
                  <i class="fas fa-user"></i> {data?.totalClient ?? 0}
                </h4>
                <p>Total Client</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-2">
          <div className="card card-wiget">
            <div className="card-body">
              <div className="card-wiget-info">
                <h4 className="count-num">
                  <i class="fas fa-user"></i> {data?.totalDistubuter ?? 0}
                </h4>
                <p>Total Distributor </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-2">
          <div className="card card-wiget">
            <div className="card-body">
              <div className="card-wiget-info">
                <h4>Total={Number(data?.totalDeposite ?? 0).toFixed(2)}</h4>
                <h4>WYZ={Number(data?.totalWyz ?? 0).toFixed(2)}</h4>
                <h4>stUSDT={Number(data?.totalStusdt ?? 0).toFixed(2)}</h4>
                <p>Total Deposit </p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-2">
          <div className="card card-wiget">
            <div className="card-body">
              <div className="card-wiget-info">
                <h4>Total={Number(data?.firstProtocolo ?? 0).toFixed(2)}</h4>
                <h4>WYZ={Number(data?.firstWyz ?? 0).toFixed(2)}</h4>
                <h4>stUSDT={Number(data?.firststudst ?? 0).toFixed(2)}</h4>
                <p>Total Deposit (10:90) </p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-2">
          <div className="card card-wiget">
            <div className="card-body">
              <div className="card-wiget-info">
                <h4>Total={Number(data?.secondProtocolo ?? 0).toFixed(2)}</h4>
                <h4>WYZ={Number(data?.secondWyz ?? 0).toFixed(2)}</h4>
                <h4>stUSDT={Number(data?.secondstudst ?? 0).toFixed(2)}</h4>
                <p>Total Deposit (20:80) </p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-2">
          <div className="card card-wiget">
            <div className="card-body">
              <div className="card-wiget-info">
                <h4>Total={Number(data?.thirdProtocolo ?? 0).toFixed(2)}</h4>
                <h4>WYZ={Number(data?.thirdWyz ?? 0).toFixed(2)}</h4>
                <h4>stUSDT={Number(data?.thirdstudst ?? 0).toFixed(2)}</h4>
                <p>Total Deposit (50:50) </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </Swiper> */}
    </>
  );
};
export default BalanceCardSlider;
