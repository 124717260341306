import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Card, Table, Button } from "react-bootstrap";
import { dashboardData } from "../../../services/api_function";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
const Protocol = () => {
  const [api, setApi] = useState([]);
  const token = useSelector((state) => state.auth.auth.token);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await dashboardData(token)
        setApi(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <Fragment>
      <Row>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          {/* <div className="input-group" style={{ maxWidth: "300px" }}>
            <input
              type="search"
              id="form1"
              className="form-control"
              placeholder="Search here..."
              onChange={handleSearch}
            />
          </div> */}
          <label class="form-label" for="form1"></label>
        </div>
        <Col lg={12}>
          <Card>
            <Card.Header
              style={{ background: "black", border: "1px solid white" }}
            >
              <Card.Title style={{ color: "white", margin: "auto" }}>
                Protocol
              </Card.Title>
            </Card.Header>
            <Card.Body
              style={{ background: "black", border: "1px solid white" }}
            >
              <Table
                responsive
                style={{
                  background: "black",
                  color: "white",
                  borderBottom: "1px solid white",
                }}
              >
                <thead>
                  <tr>
                    <th>
                      <strong> Protocol</strong>
                    </th>
                    <th>
                      <strong>Ratio</strong>
                    </th>

                    <th>
                      <strong>Tenure</strong>
                    </th>
                    <th>
                      <strong>WYZ</strong>
                    </th>
                    <th>
                      <strong>stUSDT</strong>
                    </th>
                    <th>
                      <strong>Total</strong>
                    </th>
                    <th>
                      <strong>Action</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> WYZ + stUSDT</td>
                    <td> 10:90</td>
                    <td>24 Month</td>
                    <td>{Number(api?.firstWyz)?.toFixed(2)}</td>
                    <td>{(api?.firststudst)?.toFixed(2)}</td>
                    <td>
                      {api?.firstProtocolo
                        ? api?.firstProtocolo?.toFixed(2)
                        : "0.00"}
                    </td>

                    <td>
                      <Link
                        to="/protocol-data?token=WYZ-stUSDT&ratio=10"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <i
                          className="bi bi-arrow-right-circle"
                          style={{ fontSize: "30px" }}
                        ></i>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td> WYZ + stUSDT</td>
                    <td> 20:80</td>
                    <td>36 Month</td>
                    <td>{Number(api?.secondWyz)?.toFixed(2)}</td>
                    <td>{(api?.secondstudst)?.toFixed(2)}</td>
                    <td>
                      {api.secondProtocolo
                        ? api?.secondProtocolo?.toFixed(2)
                        : "0.00"}
                    </td>
                    <td>
                      <Link
                        to="/protocol-data?token=WYZ-stUSDT&ratio=20"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <i
                          className="bi bi-arrow-right-circle"
                          style={{ fontSize: "30px" }}
                        ></i>
                      </Link>
                    </td>
                  </tr>

                  <tr>
                    <td> WYZ + stUSDT</td>
                    <td> 50:50</td>
                    <td>48 Month</td>
                    <td>{(api?.thirdWyz)?.toFixed(2)}</td>
                    <td>{(api?.thirdstudst)?.toFixed(2)}</td>
                    <td> {(api?.thirdProtocolo)?.toFixed(2)}</td>

                    <td>
                      <Link
                        to="/protocol-data?token=WYZ-stUSDT&ratio=50"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <i
                          className="bi bi-arrow-right-circle"
                          style={{ fontSize: "30px" }}
                        ></i>
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <div className="d-flex justify-content-between">
                <span>
                  {/* Page{" "} */}
                  <strong>{/* {currentPage} of {totalPages} */}</strong>
                </span>
              </div>
              {/* <div
                className="text-center mb-3 col-lg-6"
                style={{ margin: "auto" }}
              >
          <div className="filter-pagination  mt-3 bg-black"  >
                  <button
                    className="previous-button"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    style={{background:" linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",color:"black"}}
                  >
                    {"<<"}
                  </button>

                  <button
                    className="previous-button"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    style={{background:" linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",color:"black"}}

                  >
                    Previous
                  </button>

                  <button
                    className="next-button"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    style={{background:" linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",color:"black"}}
                  >
                    Next
                  </button>

                  <button
                    className="next-button"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    style={{background:" linear-gradient(90deg, #a2d254 15.9%, #ffd300 98.32%)",color:"black"}}
                  >
                    {">>"}
                  </button>

                  <span className="text-white">
                    Page {currentPage} of {totalPages}
                  </span>
                </div>
              </div> */}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Protocol;
